<template>
  <div class="step">
    <div
      class="item"
      :class="checkindex >= index ? 'on' : ''"
      v-for="(item, index) in stepData"
      :key="index"
    >
      <div class="icon">
        <div class="icon_ico">
          <van-icon name="checked" />
        </div>
        <div
          class="line"
          :class="checkindex == index + 1 ? 'checkline' : ''"
          :style="{ width: 100 / stepData.length + 'vw' }"
        ></div>
      </div>
      <div class="text">{{ item }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref,defineExpose } from "vue";
const props = defineProps({
  stepData: {
    type: Object,
    required: true,
  },
});
const { stepData } = toRefs(props);

const checkindex = ref(0);

function setStepIndex(val){
  checkindex.value = val
}

defineExpose({ setStepIndex });

</script>

<style lang="less" scoped>
.step {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  .item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .icon {
      width: 38px;
      height: 38px;
      font-size: 38px;
      line-height: 38px;
      margin-bottom: 10px;
      color: #999;
      position: relative;
      .icon_ico{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        background: #fff;
      }
      .line {
        position: absolute;
        left: 19px;
        top: 19px;
        height: 1px;
        background: #999;
        display: block;
      }
      .checkline{
        background: @theme-color;
      }
    }
    .text {
      color: #999;
      font-size: 30px;
      line-height: 30px;
      height: 30px;
      text-align: center;
    }
  }
  .item:last-of-type {
    .icon {
      .line {
        display: none;
      }
    }
  }
  .on {
    .icon {
      color: @theme-color;
    }
    .text {
      color: @theme-color;
    }
  }
}
</style>
