<template>
  <div class="ApplyStore">
    <div class="topstep">
      <Step :stepData="stepData" ref="step" />
    </div>
    <div class="contant">
      <Information
        v-show="active == 0"
        :industry="industry"
        :transmitData="transmitData"
        @getInfoContant="getInfoContant"
      />
      <BDinformation
        v-show="active == 1"
        :network="network"
        :power="power"
        :divide_desc="divide_desc"
        :transmitData="transmitData"
        :transmitDataConf="transmitDataConf"
        :example_pic="example_pic"
        @getBDContant="getBDContant"
        ref="BDinfo"
      />
    </div>
    <div class="btns" v-if="active == 0">
      <button type="button" @click="nextClick">下一步</button>
    </div>
    <div class="btns" v-else>
      <button type="button" @click="prevClick">上一步</button>
      <button type="button" @click="subClick">提交</button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import Step from "@/components/step";
import Information from "@/views/ApplyStore/component/information";
import BDinformation from "@/views/ApplyStore/component/BDinformation";
import { useRoute, useRouter } from "vue-router";
import API from "@/axios";
import { Toast } from "vant";
import mitts from "@/bus";
import wx from "jweixin-js";

const route = useRoute();
const router = useRouter();

const apply_id = ref(0);
const active = ref(0);
const stepData = ref(["录入门店信息", "门店运营信息"]);
const step = ref(null);

const industry = ref([]);
const network = ref([]);
const power = ref([]);
const divide_desc = ref("");
const opening_time = ref('')

const transmitData = ref({});
const transmitDataConf = ref({});

const example_pic = ref({})

const BDinfo = ref(null);

const subData = reactive({
  name: "",
  area: "",
  industry: "",
  address: "",
  account: "",

  password: "",
  person: "",
  mobile: "",
  divide_val: 0,
  network: "",
  power: "",
  imgs: "",
  business_img: "",
  license_img: "",
  code: "",
  fake_ratio: 0,
  add_membership_auth: 0,
  user_key: "",
  effective_date: "",
  remarks: "",
  // is_self: "",
  sys_cost_type: 0,
  sub_cost_type: 0,
  sub_cost_val: 0,
  opening_time: '00:00 ~ 23:59',
});

const lnglat = ref([]);
const lat = ref(null);
const lng = ref(null);

function getInfoContant(val) {
  subData.name = val.name;
  subData.area = val.area_text;
  subData.industry = val.industry;
  subData.address = val.address;
  subData.opening_time = val.opening_time;

  // lnglat.value = Object.entries(val.lnglat);

  for (var i in val.lnglat) {
    lnglat.value.push(val.lnglat[i]); 
  }

  if(lnglat.value.indexOf("AMap.LngLat") == -1){
    lng.value = lnglat.value[0];
    lat.value = lnglat.value[1];
  }else{
    lng.value = lnglat.value[3];
    lat.value = lnglat.value[4];
  }

  // alert("高德定位信息组件:" + JSON.stringify(lnglat.value));

}

function getBDContant(val) {
  subData.account = val.account;
  subData.password = val.password;
  subData.person = val.person;
  subData.mobile = val.mobile;
  subData.divide_val = val.divide_val;
  subData.remarks = val.remarks;
  subData.imgs = val.imgs;
  subData.business_img = val.business_img;
  subData.license_img = val.license_img;
  subData.network = val.network;
  subData.power = val.power;
  subData.add_membership_auth = val.add_membership_auth;
  subData.user_key =  val.user_key;
  subData.effective_date =  val.effective_date;
  // subData.is_self = val.is_self;
  subData.sys_cost_type = val.sys_cost_type
  subData.sub_cost_type = val.sub_cost_type
  subData.sub_cost_val = val.sub_cost_val
}

function nextClick() {
  if (subData.name == undefined) {
    Toast.fail("门店名称不能为空");
  } else if (subData.area == undefined) {
    Toast.fail("门店地域不能为空");
  } else if (subData.industry == undefined) {
    Toast.fail("门店行业不能为空");
  } else if (subData.address == undefined) {
    Toast.fail("门店地址不能为空");
  } else if (subData.opening_time == undefined) {
    Toast.fail("营业时间不能为空");
  } else {
    active.value = 1;
    step.value.setStepIndex(1);
  }
}

function prevClick() {
  if (active.value > 0) {
    active.value = active.value - 1;
    step.value.setStepIndex(active.value);
  } else {
    active.value = 0;
    step.value.setStepIndex(0);
  }
}
function subClick() {
  BDinfo.value.set_BD_Data();

  // alert("提交前坐标:" + JSON.stringify(lnglat.value));

  // alert("提交前获取经纬度:" +  lng.value + "," + lat.value);

  API({
    url: "/api/storeApplySubmit",
    method: "post",
    data: {
      apply_id: apply_id.value,
      name: subData.name,
      area_text: subData.area,
      lat: lat.value,
      lng: lng.value,
      address: subData.address,
      industry: subData.industry,
      account: subData.account,
      password: subData.password,
      person: subData.person,
      mobile: subData.mobile,
      divide_val: subData.divide_val,
      network: subData.network,
      power: subData.power,
      imgs: subData.imgs,
      business_img: subData.business_img,
      license_img: subData.license_img,
      code: subData.code,
      fake_ratio: subData.fake_ratio,
      add_membership_auth: subData.add_membership_auth,
      user_key: subData.user_key,
      effective_date: subData.effective_date,
      remarks: subData.remarks,
      // is_self: subData.is_self,
      sys_cost_type: subData.sys_cost_type,
      sub_cost_type: subData.sub_cost_type,
      sub_cost_val: subData.sub_cost_val,
      opening_time: subData.opening_time,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      apply_id.value = res.data.data.apply_id;
      router.push({
        path: "/StoreManagement/done",
        query: {
          fromstatus: 0,
          apply_id: apply_id.value,
          storeName: subData.name
        },
      });
    }
    Toast.fail(res.data.msg);
  });
}

function getInfo() {
  API({
    url: "/api/storeApplyPage",
    method: "post",
    data: {
      apply_id: apply_id.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      if (res.data.data.info) {
        transmitData.value = res.data.data.info;
      } else {
        transmitData.value = {};
      }

      transmitDataConf.value = res.data.data.conf;

      industry.value = res.data.data.conf.industry;
      divide_desc.value = res.data.data.conf.divide_desc;
      example_pic.value = res.data.data.conf;
      opening_time.value = res.data.data.conf.opening_time;

      let newNetwork = [];
      let newPower = [];

      for (const item of res.data.data.conf.network) {
        newNetwork.push(item);
      }
      network.value = newNetwork;

      for (const item of res.data.data.conf.power) {
        newPower.push(item);
      }
      power.value = newPower;

      //总线传参赋值底部菜单
      mitts.emit('navs', res.data.data.main_nav);
      mitts.emit("toptitle", false);
      mitts.emit('navindex', 'business');
    }
  });
}

const wxposition = ref();

function getwxconfig() {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {
    if (res.data.status == 200) {
      wxposition.value = res.data.data;

      wx.config({
        debug: false,
        appId: wxposition.value.appId,
        timestamp: wxposition.value.timestamp,
        nonceStr: wxposition.value.nonceStr,
        signature: wxposition.value.signature,
        jsApiList: [
          "checkJsApi",
          "getLocation",
        ],
      });

      wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
    }
  });
}

function getwxLocalposition() {
  wx.getLocation({
    type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
    success: function (res) {
      if (res) {
        lat.value = res.latitude
        lng.value = res.longitude
      }
    },
  });
}

onMounted(() => {
  if (route.query.apply_id) {
    apply_id.value = route.query.apply_id;
  } else {
    apply_id.value = "";
  }
  getInfo();

  getwxconfig()

  getwxLocalposition();

  setTimeout(() => {
    mitts.emit("toptitle", false);
    mitts.emit("closeLoading");
  }, 500);

  // mitts.on("sendlnglat", (val) => {
  //   setTimeout(()=>{
  //     subData.lat = val[1];
  //     subData.lng = val[0];
  //   },300)
  // });
});
</script>

<style lang="less" scoped>
.ApplyStore {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
}
.topstep {
  overflow: hidden;
}
.contant {
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 20px;
}
.btns {
  display: flex;
  padding: 0 20px;
  margin-bottom: 20px;
  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }
  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
    flex: 2;
  }
}
</style>
