<template>
  <div class="BDinformation">
    <div class="setinfo">
      <div class="item">
        <div class="tit">门店帐号<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="storeAccount" placeholder="请填写帐号" />
        </div>
      </div>
      <div class="item">
        <div class="tit">门店密码<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="storePassWord" placeholder="请填写密码" />
        </div>
      </div>
      <div class="item">
        <div class="tit">门店联系人<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="storePerson" placeholder="请填写姓名" />
        </div>
      </div>
      <div class="item">
        <div class="tit">联系方式<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="mobile" placeholder="请填写联系方式" />
        </div>
      </div>
      <div class="item">
        <div class="tit">门店比例<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="divide_val" placeholder="请填写分成比例" />
        </div>
        <div class="aftertext">%</div>
        <div class="tip" @click="showTip(divide_desc)">
          <van-icon name="warning-o" color="#f60" />
        </div>
      </div>
      <div class="item" v-if="transmitDataConf.show_fake == 1">
        <div class="tit">门店展示比例</div>
        <div class="inp">
          <input type="text" v-model="fake_ratio" placeholder="请填写分成比例" />
        </div>
        <div class="aftertext">%</div>
      </div>

      <div class="item">
        <div class="tit">是否添加会员</div>
        <div class="inp">
          <van-switch v-model="isVipCheck" inactive-color="#d5d5d5" @change="VipchangeClick" />
        </div>
      </div>

      <div class="item" v-if="isVipCheck">
        <div class="tit">用户id</div>
        <div class="inp">
          <input type="text" v-model="user_key" placeholder="请填写用户id" />
        </div>
      </div>
      <div class="item" v-if="isVipCheck">
        <div class="tit">生效日期</div>
        <div class="inp" @click="selectEffective">
          {{effective_date?effective_date:"请填写生效日期"}}
        </div>
      </div>
<!--      <div class="item vertical_item">-->
<!--        <div class="tit">是否自营<span>*</span></div>-->
<!--        <div class="inp" @click="isSelfShow">-->
<!--          {{is_self_text?is_self_text:"请选择是否自营"}}-->
<!--        </div>-->
<!--        <div class="aftertext"><van-icon name="arrow" /></div>-->
<!--      </div>-->
    </div>
    <div class="storenet">
      <div class="title">门店网络情况</div>
      <div class="item">
        <div class="tit">现场网络<span>*</span></div>
        <div class="inp" @click="netShowClick">
          {{networktext?networktext:"请选择网络情况"}}
        </div>
        <div class="aftertext"><van-icon name="arrow" /></div>
      </div>
      <div class="item">
        <div class="tit">现场用电<span>*</span></div>
        <div class="inp" @click="powerShowClick">
          {{powertext?powertext:"请选择用电情况"}}
        </div>
        <div class="aftertext"><van-icon name="arrow" /></div>
      </div>
    </div>
    <div class="storenet">
      <div class="title">系统分成</div>
      <div class="item">
        <div class="tit">系统分成模式<span>*</span></div>
        <div class="inp" @click="sysCostTypeShowClick">
          {{sys_cost_type_text?sys_cost_type_text:"请选择系统分成模式"}}
        </div>
        <div class="aftertext"><van-icon name="arrow" /></div>
      </div>
      <div class="item">
        <div class="tit">系统分成类型<span>*</span></div>
        <div class="inp" @click="subCostTypeShowClick">
          {{sub_cost_type_text?sub_cost_type_text:"请选择系统分成类型"}}
        </div>
        <div class="aftertext"><van-icon name="arrow" /></div>
      </div>
      <div class="item">
        <div class="tit">系统分成金额<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="sub_cost_val" placeholder="请填写系统分成金额" />
        </div>
      </div>
    </div>

    <div class="piclist">
      <div class="item">
        <div class="text">
          <div class="top">
            <div class="title">设备摆放位置</div>
            <button type="button" class="example" @click="showExample(0)">
              参考示例
            </button>
          </div>
          <div class="tip">图片大小不超过20M</div>
        </div>
        <div class="pic">
          <van-uploader
            v-model="euipmentPic"
            :max-size="20 * 1024 * 1024"
            :max-count="1"
            @oversize="onOversize"
            :after-read="EQ_afterRead"
          />
        </div>
      </div>
      <div class="item">
        <div class="text">
          <div class="top">
            <div class="title">BD和门头合照</div>
            <button type="button" class="example" @click="showExample(1)">
              参考示例
            </button>
          </div>
          <div class="tip">图片大小不超过20M</div>
        </div>
        <div class="pic">
          <van-uploader
            v-model="BDstorePic"
            :max-size="20 * 1024 * 1024"
            :max-count="1"
            @oversize="onOversize"
            :after-read="BD_afterRead"
          />
        </div>
      </div>
      <div class="item">
        <div class="text">
          <div class="top">
            <div class="title">营业执照</div>
            <button type="button" class="example" @click="showExample(2)">
              参考示例
            </button>
          </div>
          <div class="tip">图片大小不超过20M</div>
        </div>
        <div class="pic">
          <van-uploader
            v-model="licensePic"
            :max-size="20 * 1024 * 1024"
            :max-count="1"
            @oversize="onOversize"
            :after-read="LI_afterRead"
          />
        </div>
      </div>
    </div>

    <div class="remark">
      <div class="tit">备注信息</div>
      <div class="textarea">
        <textarea rows="5" v-model="remarktext"></textarea>
      </div>
    </div>

    <van-popup v-model:show="netShow" position="bottom" round>
      <div class="titlebox">
        <button type="button" @click="netCancer">取消</button>
        <div class="title">现场网络</div>
        <button type="button" @click="netOk">确认</button>
      </div>
      <div class="checkcont">
          <van-checkbox v-model="item.checked" v-for="(item, index) in network" :key="index" @click="netChange(index)">{{item.title}}</van-checkbox>
      </div>
    </van-popup>
    <van-popup v-model:show="powerShow" position="bottom" round>
      <div class="titlebox">
        <button type="button" @click="powerCancer">取消</button>
        <div class="title">现场用电</div>
        <button type="button" @click="powerOk">确认</button>
      </div>
      <div class="checkcont">
          <van-checkbox v-model="item.checked" v-for="(item, index) in power" :key="index" @click="powerChange(index)">{{item.title}}</van-checkbox>
      </div>
    </van-popup>
    <van-popup v-model:show="exampleShow" round :style="{ width: '80%' }">
      <div class="picexample">
        <div class="pic" v-if="examplePic">
          <img :src="examplePic" alt="" />
        </div>
        <div class="text" v-html="exampleText"></div>
        <div class="btn">
          <button type="button" @click="cancerExample">我知道了</button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="sys_cost_type_show" position="bottom" round>
      <van-picker
          title="系统分成模式"
          :columns="sys_cost_type_list"
          @confirm="onSysCostTypeConfirm"
          @cancel="onSysCostTypeCancel"
          :default-index="sys_cost_type_def"
      />
    </van-popup>
    <van-popup v-model:show="sub_cost_type_show" position="bottom" round>
      <van-picker
          title="系统分成类型"
          :columns="sub_cost_type_list"
          @confirm="onSubCostTypeConfirm"
          @cancel="onSubCostTypeCancel"
          :default-index="sub_cost_type_def"
      />
    </van-popup>

    <van-calendar v-model:show="dateshow" :min-date="new Date(2010, 1, 1)"
      :show-confirm="false" @confirm="onConfirm" />

<!--    <van-popup v-model:show="is_self_show" position="bottom" round>-->
<!--        <van-picker-->
<!--            title="是否自营"-->
<!--            :columns="is_self_list"-->
<!--            @confirm="onIsSelfConfirm"-->
<!--            @cancel="onIsSelfCancel"-->
<!--            :default-index="is_self_def"-->
<!--        />-->
<!--    </van-popup>-->
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, onMounted, defineEmits, defineExpose, onUpdated } from "vue";
import API from "@/axios";

const emit = defineEmits(["getBDContant"]);

const props = defineProps({
  network: {
    type: Array,
    required: true,
  },
  power: {
    type: Array,
    required: true,
  },
  transmitData: {
    type: Object,
    required: true,
  },
  divide_desc: {
    type: String,
    required: true,
  },
  example_pic: {
    type: Object,
    required: true,
  },
  transmitDataConf: {
    type: Object,
    required: true,
  },
});

const { network, power, transmitData, divide_desc, example_pic, transmitDataConf } = toRefs(props);

const storeAccount = ref("");
const storePassWord = ref("");
const storePerson = ref("");
const mobile = ref("");
const divide_val = ref(0);
const remarktext = ref("");
const euipmentPic_url = ref("");
const BDstorePic_url = ref("");
const licensePic_url = ref("");
const networktext = ref("");
const network_ID = ref("");
const powertext = ref("");
const power_ID = ref("");
const fake_ratio = ref(0);
const auth = ref(0);
const user_key = ref("");
const effective_date = ref("");
const isVipCheck = ref(false)

const dateshow = ref(false)

const networkchecked = ref([]);
const powerchecked = ref([]);

// const is_self_list = ref([]);
// const is_self_show = ref(false);
// const is_self = ref("");
// const is_self_text = ref("");
// const is_self_def = ref([]);

const netShow = ref(false);

const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const onConfirm = (value) => {
  dateshow.value = false;
  effective_date.value = formatDate(value);
};

function selectEffective(){
  dateshow.value = true;
}

function netShowClick() {
  netShow.value = true;
}

function netCancer() {
  netShow.value = false;
}

function netOk() {
  let checkArry = [];
  networkchecked.value = [];
 
  for (const item of network.value) {
    if (item.checked) {
      checkArry.push(item.title);
      networkchecked.value.push(item.val)
    }
  }

  network_ID.value = networkchecked.value.join(",");
  networktext.value = checkArry.join("/");

  netShow.value = false;
}

function netChange(index) {
  network.value[index].checked !== network.value[index].checked
}

const powerShow = ref(false);

function powerShowClick() {
  powerShow.value = true;
}

function powerCancer() {
  powerShow.value = false;
}

function powerOk() {
  let checkArry = [];
  powerchecked.value = [];

  for (const item of power.value) {
    if (item.checked) {
      checkArry.push(item.title);
      powerchecked.value.push(item.val)
    }
  }

  power_ID.value = powerchecked.value.join(",");
  powertext.value = checkArry.join("/");

  powerShow.value = false;

}

function powerChange(index) {
  power.value[index].checked !== power.value[index].checked
}

// function isSelfShow() {
//   is_self_show.value = true;
// }
// function onIsSelfConfirm(value, index) {
//   is_self_show.value = false;
//   is_self_text.value = value;
//   is_self.value = transmitDataConf.value.is_self[index].val;
// }
// function onIsSelfCancel() {
//   is_self_show.value = false;
// }

const sys_cost_type_list = ref([])
const sys_cost_type_text = ref("");
const sys_cost_type = ref("");
const sys_cost_type_show = ref(false)
const sys_cost_type_def = ref(0)
function sysCostTypeShowClick() {
  sys_cost_type_show.value = true;
}
function onSysCostTypeConfirm(value, index) {
  sys_cost_type_show.value = false;
  sys_cost_type_text.value = value;
  sys_cost_type.value = transmitDataConf.value.sys_cost_type_list[index].val;
}
function onSysCostTypeCancel() {
  sys_cost_type_show.value = false;
}

const sub_cost_type_list = ref([])
const sub_cost_type_text = ref("");
const sub_cost_type = ref("");
const sub_cost_type_show = ref(false)
const sub_cost_type_def = ref(0)
function subCostTypeShowClick() {
  sub_cost_type_show.value = true;
}

function onSubCostTypeConfirm(value, index) {
  sub_cost_type_show.value = false;
  sub_cost_type_text.value = value;
  sub_cost_type.value = transmitDataConf.value.sub_cost_type_list[index].val;
}
function onSubCostTypeCancel() {
  sub_cost_type_show.value = false;
}
const sub_cost_val = ref(0)

const euipmentPic = ref([]);
const BDstorePic = ref([]);
const licensePic = ref([]);

const exampleShow = ref(false);
const examplePic = ref("");
const exampleText = ref("");
function showExample(val) {
  if (val == 0) {
    examplePic.value = example_pic.value.example_devices;
    exampleText.value = "请上传含有设备或即将摆放设备的门店环境照片";
  } else if (val == 1) {
    examplePic.value = example_pic.value.example_business;
    exampleText.value = "请上传您与门店门面的合影";
  } else if (val == 2) {
    examplePic.value = example_pic.value.example_license;
    exampleText.value = "请上传清晰，完整的门店营业执照照片";
  }

  exampleShow.value = true;
}

function EQ_afterRead(file) {
  file.status = "uploading";
  file.message = "上传中";
  let form = new FormData();
  form.append("file", file.file);
  API({
    url: "/web/uploadImg",
    method: "post",
    data: form,
    timeout: 60000,
  }).then((res) => {
    file.url = res.data.url;
    file.status = "done";
    euipmentPic_url.value = file.url;
  });
}

function BD_afterRead(file) {
  file.status = "uploading";
  file.message = "上传中";
  let form = new FormData();
  form.append("file", file.file);
  API({
    url: "/web/uploadImg",
    method: "post",
    data: form,
    timeout: 60000,
  }).then((res) => {
    file.url = res.data.url;
    file.status = "done";
    BDstorePic_url.value = file.url;
  });
}

function LI_afterRead(file) {
  file.status = "uploading";
  file.message = "上传中";
  let form = new FormData();
  form.append("file", file.file);
  API({
    url: "/web/uploadImg",
    method: "post",
    data: form,
    timeout: 60000,
  }).then((res) => {
    file.url = res.data.url;
    file.status = "done";
    licensePic_url.value = file.url;
  });
}

function cancerExample() {
  exampleShow.value = false;
}

function showTip(val) {
  exampleShow.value = true;
  exampleText.value = val;
}

function VipchangeClick(){
  if(isVipCheck.value){
    auth.value = 1
  }else{
    auth.value = 0
    user_key.value = ""
    effective_date.value = ""
  }
}

function set_BD_Data() {
  let data = {
    account: storeAccount.value,
    password: storePassWord.value,
    person: storePerson.value,
    mobile: mobile.value,
    divide_val: divide_val.value,
    remarks: remarktext.value?remarktext.value:'',
    imgs: euipmentPic_url.value?euipmentPic_url.value:'',
    business_img: BDstorePic_url.value?BDstorePic_url.value:'',
    license_img: licensePic_url.value?licensePic_url.value:'',
    network: network_ID.value,
    power: power_ID.value,
    fake_ratio: fake_ratio.value,
    add_membership_auth: auth.value,
    user_key: user_key.value,
    effective_date: effective_date.value,
    // is_self: is_self.value,
    sys_cost_type: sys_cost_type.value,
    sub_cost_type: sub_cost_type.value,
    sub_cost_val: sub_cost_val.value,
  };
  emit("getBDContant", data);
}

defineExpose({ set_BD_Data });

function ini() {
  storeAccount.value = transmitData.value.account;
  storePassWord.value = transmitData.value.password;
  storePerson.value = transmitData.value.person;
  mobile.value = transmitData.value.mobile;
  divide_val.value = transmitData.value.divide_val;
  remarktext.value = transmitData.value.remarks;
  euipmentPic_url.value = transmitData.value.imgs;
  BDstorePic_url.value = transmitData.value.business_img;
  licensePic_url.value = transmitData.value.license_img;
  network_ID.value = transmitData.value.network;
  power_ID.value = transmitData.value.power;
  fake_ratio.value = transmitData.value.fake_ratio;
  sys_cost_type.value = transmitData.value.sys_cost_type;
  sub_cost_type.value = transmitData.value.sub_cost_type;
  sub_cost_val.value = transmitData.value.sub_cost_val;

  if(transmitData.value.network){
    if (transmitData.value.network.indexOf(',') == -1) {
      networkchecked.value = transmitData.value.network;
    } else {
      networkchecked.value = transmitData.value.network.split(',');
    }
  }else{
    networkchecked.value = "";
  }

  if(transmitData.value.power){
    if (transmitData.value.power.indexOf(',') == -1) {
      powerchecked.value = transmitData.value.power;
    } else {
      powerchecked.value = transmitData.value.power.split(',');
    }
  }else{
    powerchecked.value = "";
  }

  let netcheckArry = []
  if (network_ID.value) {
    for (const item of network.value) {
      if (network_ID.value.indexOf(item.val.toString()) !== -1) {
        netcheckArry.push(item.title);
        
      }
    }
  }
  networktext.value = netcheckArry.join("/");

  let powercheckArry = []
  if(power_ID.value){
    for (const item of power.value) {
      if (power_ID.value.indexOf(item.val.toString()) !== -1) {
        powercheckArry.push(item.title);
      }
    }
  }
  powertext.value = powercheckArry.join("/");

  // 是否自营
  // let is_self_config = transmitDataConf.value.is_self;
  // for (let i = 0; i < is_self_config.length; i++) {
  //   is_self_list.value.push(is_self_config[i].title);
  //   if (is_self_config[i].checked == 1) {
  //     is_self_def.value = i;
  //   }
  // }
  // is_self_text.value = transmitData.value.is_self;

  if(transmitData.value.imgs){
    euipmentPic.value.push({url:transmitData.value.imgs});
  }
   
  if(transmitData.value.business_img){
    BDstorePic.value.push({url:transmitData.value.business_img});
  }

  if(transmitData.value.license_img){
    licensePic.value.push({url:transmitData.value.license_img});
  }

  for (let i = 0; i < transmitDataConf.value.sys_cost_type_list.length; i++) {
    sys_cost_type_list.value.push(transmitDataConf.value.sys_cost_type_list[i].title);
    if(transmitDataConf.value.sys_cost_type_list[i].checked == 1){
      sys_cost_type_def.value = i
      sys_cost_type_text.value = transmitDataConf.value.sys_cost_type_list[i].title;
    }
  }

  for (let i = 0; i < transmitDataConf.value.sub_cost_type_list.length; i++) {
    sub_cost_type_list.value.push(transmitDataConf.value.sub_cost_type_list[i].title);
    if(transmitDataConf.value.sub_cost_type_list[i].checked == 1){
      sub_cost_type_def.value = i
      sub_cost_type_text.value = transmitDataConf.value.sub_cost_type_list[i].title;
    }
  }

  emit("getBDContant", transmitData.value);
}

const getiniSwitch = ref(true)

onMounted(() => {
  // console.log("11121")
  // setTimeout(()=>{
  //   ini();
  // },500)
  getiniSwitch.value = true
});

onUpdated(()=>{
  if (getiniSwitch.value) {
    setTimeout(() => {
      ini();
    }, 1000)
  }

  getiniSwitch.value = false
})
</script>

<style lang="less" scoped>
.BDinformation {
}

.setinfo {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;
  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .tit {
      margin-right: 20px;
      span {
        color: #f00;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 26px;
        font-size: 30px;
      }
    }
    .inp {
      min-width: 0;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      input {
        width: 100%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .aftertext {
      font-weight: 600;
      color: #999;
    }
    .tip {
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }
  .item.vertical_item {
    padding: 0;
    align-items: center;
    .inp {
      color: #999;
    }
  }
}
.storenet {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  .title {
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    color: @font-secondary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tit {
      margin-right: 20px;
      span {
        color: #f00;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 26px;
        font-size: 30px;
      }
    }
    .inp {
      min-width: 0;
      flex: 1;
      display: flex;
      color: #999;
      justify-content: flex-end;
      text-align: right;
      input {
        width: 100%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity:1;
        background: none;
      }
    }
    .aftertext {
      font-weight: 600;
      color: #999;
    }
  }
}
.piclist {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f5f5f5;
    .text {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .top {
        .title {
          margin-bottom: 10px;
          span {
            color: #f00;
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            line-height: 26px;
            font-size: 30px;
          }
        }
        .example {
          border-radius: 10px;
          padding: 5px;
          background: none;
          border: 1px solid @theme-secondary-color;
          font-size: 24px;
          color: @theme-secondary-color;
        }
      }
      .tip {
        font-size: 28px;
        color: #999;
      }
    }
  }
  .item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}
.remark {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  .tit {
    margin-bottom: 20px;
    span {
      color: #f00;
    }
  }
  .textarea {
    width: 100%;
    textarea {
      width: 100%;
      padding: 5px 10px;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      box-sizing: border-box;
      resize: none;
      background: #f9f9f9;
    }
  }
}
.picexample {
  padding: 20px;
  .pic {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
      display: block;
    }
  }
  .text {
    width: 100%;
    margin-bottom: 20px;
  }
  .btn {
    width: 100%;
    button {
      font-size: 28px;
      height: 66px;
      line-height: 66px;
      border-radius: 10px;
      border: 3px solid @theme-secondary-color;
      background: @theme-secondary-color;
      color: @font-white-color;
      width: 100%;
    }
  }
}
.toasttext {
  font-size: 20px;
}

.titlebox {
  display: flex;
  padding: 20px;
  .title {
    flex: 1;
    text-align: center;
  }
  button {
    background: none;
  }
}
.checkcont{
  padding: 0 20px;
}
</style>
