<template>
  <div class="MapContainer">
    <div class="Mapbox">
      <div class="Map" id="container">
        <el-amap :center="center" :zoom="zoom" @click="clickMap">
          <el-amap-control-geolocation :showMarker="false" :showCircle="false" @complete="getLocation" @error="errorLocation" />
          <el-amap-search-box
            @select="selectPoi"
            placeholder="请搜索或点击右下角获取定位"
          />
          <el-amap-marker :position="markerPosition">
            <div class="mapMaker">
              <div class="icon"><img src="@/assets/img/mark_bs.png" alt="" /></div>
              <div class="text">{{ addrtext }}</div>
            </div>
          </el-amap-marker>
        </el-amap>
      </div>
      <!-- <div class="searchmap">
        <input type="text" v-model="addkeyword" placeholder="点击地图或输入地址" />
        <button type="button" @click="searchClick">搜索</button>
      </div> -->
      <div class="btns">
        <button type="button" @click="subbtn">确定</button>
        <button type="button" class="cancer" @click="closebtn">关闭</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineExpose,defineProps,toRefs, onMounted} from "vue";
// import mitts from "@/bus";
import wx from 'jweixin-js';
// import API from "@/axios";

const emit = defineEmits(["closePup", "sendData"]);
const zoom = ref(18);
const center = ref([118.11022, 24.490474]);
const markerPosition = ref([118.11022, 24.490474]);
const addrtext = ref("");

const props = defineProps({
  wxposition: {
    type: Object,
    required: false,
  },
});

const { wxposition } = toRefs(props);


function getLocation(e) {
  console.log(e)
  getAddr(e.position);
  center.value = e.position;
  markerPosition.value = e.position;
  // getwxLocalposition()
}

// 定位获取不到时
function errorLocation(){
  getwxLocalposition()
}
                  
function clickMap(e) {
  center.value = [e.lnglat.lng, e.lnglat.lat];
  markerPosition.value = [e.lnglat.lng, e.lnglat.lat];
  getAddr([e.lnglat.lng, e.lnglat.lat]);
}

function selectPoi(val) {
  center.value = [val.poi.location.lng, val.poi.location.lat];
  markerPosition.value = [val.poi.location.lng, val.poi.location.lat];
  if (val.poi.address == "") {
    addrtext.value = val.poi.name;
  } else {
    addrtext.value = val.poi.address;
  }
}

function getAddr(val) {
  AMap.plugin("AMap.Geocoder", function () {
    let geocoder = new AMap.Geocoder();
    geocoder.getAddress(val, function (status, res) {
      if (res.info == "OK") {
        // 显示点标志的信息窗口
        addrtext.value = res.regeocode.formattedAddress;
      }
    });
  });
}

getAddr(center.value);

function subbtn() {
  let data = {
    lnglat: center.value,
    address: addrtext.value,
  };
  
  // mitts.emit("sendlnglat", center.value);
  emit("sendData", data);
}

function closebtn() {
  emit("closePup", false);
}

function getMap(val) {
  center.value = val.lnglat;
  markerPosition.value = val.lnglat;
  addrtext.value = val.address;
}

function getlocalpositon(val) {
  center.value = val;
  markerPosition.value = val;
  getAddr(val);
}

defineExpose({ getMap, getlocalpositon });

function getwxLocalposition(){
    wx.getLocation({
      type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: function (res) {
        center.value = [res.longitude, res.latitude];
        markerPosition.value = [res.longitude, res.latitude];
        getAddr(res.longitude, res.latitude);
        
      }
    });
}


onMounted(()=>{
  if (wxposition.value !== undefined) {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: wxposition.value.appId, // 必填，公众号的唯一标识
      timestamp: wxposition.value.timestamp, // 必填，生成签名的时间戳
      nonceStr: wxposition.value.nonceStr, // 必填，生成签名的随机串
      signature: wxposition.value.signature,// 必填，签名
      jsApiList: [
        'checkJsApi',   //判断当前客户端版本是否支持指定JS接口
        'getLocation',
      ] // 必填，需要使用的JS接口列表
    });

    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    });
  }
})


</script>

<style lang="less" scoped>
.MapContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.6);
}

.Mapbox {
  width: 86%;
  height: 50vh;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-flow: column;
}
.Map {
  width: 100%;
  height: 10px;
  flex-grow: 1;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}
.mapMaker {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 50vw;
  margin-left: -25vw;
  margin-top: -34%;
  .icon {
    width: 38px;
    img {
      display: block;
      width: 100%;
    }
  }
  .text {
    font-size: 24px;
    line-height: 28px;
    max-width: 100%;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
  }
}
.searchmap {
  width: 100%;
  display: flex;
  input {
    flex: 1;
    margin-right: 20px;
    background: #f5f5f5;
    height: 60px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 24px;
    line-height: 60px;
  }
  button {
    background: @theme-secondary-color;
    color: #fff;
    font-size: 24px;
    padding: 0 20px;
    border-radius: 5px;
  }
}
.btns {
  position: absolute;
  bottom: -80px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  button {
    background: @theme-secondary-color;
    color: #fff;
    font-size: 32px;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 0 20px;
  }
  .cancer {
    background: #999;
  }
}
</style>
