<template>
  <div class="information">
    <div class="preview">
      <div class="title">预览效果展示</div>
      <div class="item">
        <div class="title">{{ preStoreName }}</div>
        <div class="synopsis">{{ preAddress }}</div>
        <div class="equipment">设备数量：0</div>
        <div class="telecall">
          <div class="callitem"><span>签约BD：BD名称</span><van-icon name="phone" /></div>
          <div class="callitem"><span>运维BD：BD名称</span><van-icon name="phone" /></div>
        </div>
      </div>
    </div>
    <div class="setinfo">
      <div class="item">
        <div class="tit">门店名称<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="storeName" placeholder="请输入门店名称" @blur="blurInp" />
        </div>
      </div>
      <div class="item">
        <div class="tit">门店地域<span>*</span></div>
        <div class="inp" @click="showArea">
          {{storeArea?storeArea:"请选择门店地域"}}
        </div>
      </div>
      <div class="item">
        <div class="tit">门店地址<span>*</span></div>
        <div class="inp">
          <div class="openmap" @click="shoMmapClick">
            <van-icon name="map-marked" color="#486ffb" />
          </div>
          <input type="text" v-model="AddText" placeholder="请输入门店地址" @blur="blurInp" @focus="intoInp" />
          
        </div>
      </div>
      <div class="item">
        <div class="tit">所属行业<span>*</span></div>
        <div class="inp" @click="showIndustry">
          {{industryName?industryName:"请选择所属行业"}}
        </div>
      </div>
      <div class="item">
        <div class="tit">营业时间<span>*</span></div>
        <div class="inp" @click="openingTimeShow">
          {{opening_time?opening_time:""}}
        </div>
      </div>
    </div>
    <van-popup v-model:show="areaShow" position="bottom" round>
      <van-area title="选择地域" :area-list="areaList" @confirm="okClick" @cancel="cancerClick" />
    </van-popup>
    <van-popup v-model:show="industryShow" position="bottom" round>
      <van-picker title="选择行业" :columns="industry" @confirm="okindustry" @cancel="cancerindustry" />
    </van-popup>

    <Map v-if="showMap" @closePup="closePup" @sendData="sendData" @getwxLocalposition="getwxLocalposition"
      :wxposition="wxposition" ref="BackMapData" />
    <van-popup v-model:show="openingStartShow" position="bottom" round>
      <van-datetime-picker
          v-model="openingStart"
          type="time"
          title="开始时间"
          @confirm="openingStartConfirm"
          @cancel="openingStartCancel"
      />
    </van-popup>
    <van-popup v-model:show="openingEndShow" position="bottom" round>
      <van-datetime-picker
          v-model="openingEnd"
          title="结束时间"
          type="time"
          @cancel="openingEndCancel"
          @confirm="openingOk"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps, toRefs, defineEmits } from "vue";
import { areaList } from "@vant/area-data";
import API from "@/axios";
import Map from "@/components/MapContainer";
const emit = defineEmits(["getInfoContant"]);

const props = defineProps({
  industry: {
    type: Array,
    required: true,
  },
  transmitData: {
    type: Object,
    required: true,
  },
});
const { industry, transmitData } = toRefs(props);

const preStoreName = ref("");
const preAddress = ref("");

const storeName = ref("");
const storeArea = ref("");
const industryName = ref("");
const showMap = ref(false);
const wxposition = ref();
const AddText = ref("");
const Maplnglat = ref([]);

const areaShow = ref(false);
const industryShow = ref(false);

function showArea() {
  areaShow.value = true;
}

function okClick(val) {
  storeArea.value = val
    .filter((item) => !!item)
    .map((item) => item.name)
    .join("/");
  areaShow.value = false;

  let data = {
    name: storeName.value,
    area_text: storeArea.value,
    industry: industryName.value,
    address: AddText.value,
    lnglat: Maplnglat.value,
    opening_time: opening_time.value
  };
  console.log('okclick', opening_time.value)
  emit("getInfoContant", data);
}

function cancerClick() {
  areaShow.value = false;
}

function showIndustry() {
  industryShow.value = true;
}

function okindustry(val) {
  industryName.value = val
    .filter((item) => !!item)
    .map((item) => item.text)
    .join("/");
  industryShow.value = false;

  let data = {
    name: storeName.value,
    area_text: storeArea.value,
    industry: industryName.value,
    address: AddText.value,
    lnglat: Maplnglat.value,
    opening_time: opening_time.value
  };
  emit("getInfoContant", data);
}

function cancerindustry() {
  industryShow.value = false;
}

const opening_time = ref('')
const openingStart = ref('')
const openingStartShow = ref(false)
const openingEnd = ref('')
const openingEndShow = ref(false)
// 点击营业时间
function openingTimeShow(){
  openingStartShow.value = true
}
function openingStartCancel(){
  openingStartShow.value = false
}
function openingStartConfirm(value){
  openingStartShow.value = false
  openingEndShow.value = true
  openingStart.value = value
}
// 选择营业时间完成
function openingOk(value){
  openingEndShow.value = false
  openingEnd.value = value
  opening_time.value = openingStart.value + ' ~ ' + openingEnd.value
  let data = {
    name: storeName.value,
    area_text: storeArea.value,
    industry: industryName.value,
    address: AddText.value,
    lnglat: Maplnglat.value,
    opening_time: opening_time.value
  };
  emit("getInfoContant", data);
}
function openingEndCancel(){
  openingEndShow.value = false
  openingStartShow.value = true
}

function getwxconfig() {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {
    if (res.data.status == 200) {
      wxposition.value = res.data.data;
    }
  });
}

function intoInp() {
  if (AddText.value == undefined || AddText.value == "") {
    showMap.value = true;
  }
}

function shoMmapClick() {
  showMap.value = true;
}

function closePup(val) {
  showMap.value = val;
}

function sendData(val) {

  // alert("地图到子组件:" + JSON.stringify(val));

  AddText.value = val.address;
  preAddress.value = val.address;
  Maplnglat.value = val.lnglat;
  showMap.value = false;

  let data = {
    name: storeName.value,
    area_text: storeArea.value,
    industry: industryName.value,
    address: AddText.value,
    lnglat: Maplnglat.value,
    opening_time: opening_time.value,
  };

  // alert("子组件获取坐标:" + JSON.stringify(data));

  emit("getInfoContant", data);
}

function blurInp() {
  preStoreName.value = storeName.value;
  preAddress.value = AddText.value;
  let data = {
    name: storeName.value,
    area_text: storeArea.value,
    industry: industryName.value,
    address: AddText.value,
    lnglat: Maplnglat.value,
    opening_time: opening_time.value
  };
  emit("getInfoContant", data);
}

function ini() {
  storeName.value = transmitData.value.name;
  storeArea.value = transmitData.value.area_text;
  industryName.value = transmitData.value.industry;
  AddText.value = transmitData.value.address;
  preStoreName.value = transmitData.value.name;
  preAddress.value = transmitData.value.address;
  if (!transmitData.value.name) {
    preStoreName.value = "门店名称1";
  }
  if (!transmitData.value.address) {
    preAddress.value = "门店地址";
  }

  opening_time.value = transmitData.value.opening_time;
  if (!opening_time.value) {
    opening_time.value = '00:00 ~ 23:59'
  }
  if (opening_time.value) {
    var opening_time_list = opening_time.value.split(' ~ ')
    if (opening_time_list.length == 2) {
      openingStart.value = opening_time_list[0]
      openingEnd.value = opening_time_list[1]
    }
  }

  emit("getInfoContant", transmitData.value);
}

onMounted(() => {
  // if (location.href.indexOf("#reloaded") == -1) {
  //   location.href = location.href + "#reloaded";
  //   location.reload();
  // }
  getwxconfig();
  setTimeout(()=>{
    ini();
  },500)
  
});
</script>

<style lang="less" scoped>
.information {
  height: 100%;
}

.preview {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;

  .title {
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    color: @font-secondary-color;
    margin-bottom: 20px;
  }

  .item {
    padding: 15px;
    background: #f5f5f5;
    box-sizing: border-box;
    border: 1px solid #e1e1e1;
    border-radius: 10px;

    .title {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }

    .synopsis {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      height: @subtitle-size;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    .telecall {
      display: flex;
      flex-flow: wrap;

      .callitem {
        background: none;
        font-size: 12px;
        border-radius: 10px;
        border: 3px solid @theme-secondary-color;
        color: @theme-secondary-color;
        padding: 5px;
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 10px;

        span {
          margin-right: 10px;
        }
      }
    }

    .equipment {
      display: flex;
      font-size: 28px;
      margin-bottom: 10px;
      color: @font-secondary-color;
    }
  }
}

.setinfo {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;

  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;

    .tit {
      margin-right: 20px;
      span {
        color: #f00;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 26px;
        font-size: 30px;
      }
    }

    .inp {
      min-width: 0;
      flex: 1;
      display: flex;
      align-items: center;
      color: #999;
      justify-content: flex-end;
      input {
        flex: 1;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 1;
        background: none;
      }

      .openmap {
        margin-right: 20px;
      }
    }
  }
}
</style>
